<template>
  <div class="wrapper">
    <img loading="lazy" alt="icon" id="img-top" src="https://clubejecutivo.s3.us-east-2.amazonaws.com/assets/images/emprendedores/financing.jpg" />
    <div class="background first">
      <div class="container">
        <h2>{{ $t('emprendedores.financing') }}</h2>
        <div id="text-financing">
          <strong>El CLUB Emprendedores</strong> te ayuda a definir tus necesidades
          de capital y acceder a financiación.
        </div>
      </div>
    </div>
    <div class="second">
      <div class="container">
        <div>
          <h3 id="first">ASESORAMIENTO FINANCIERO</h3>
          <p>
            Desde el equipo del club de Emprendedores te apoyaremos en la elaboración
            de los <strong>estados económicos financieros, el cálculo de las
            necesidades de capital, identificación de fuentes de financiación, y
            preparación de tu proyecto</strong> para su presentación a entidadese
            inversores.
            <a href="#">Solicita información.</a>
          </p>
        </div>
        <div>
          <h3>ACUERDOS CON ENTIDADES FINANCIERAS</h3>
          <p>
            El <strong>club de emprendedores</strong> tiene acuerdos con entidades
            financieras públicas y privadas para facilitar financiación en condiciones
            preferentes a los proyectos emprendedores con proyectos sólidos.
            <a href="#">Solicita información.</a>
         </p>
        </div>
        <div class="more-info">
          <div id="text">
            Para ampliar información y/o tramitar solicitudes contactar con:
          </div>
          <div id="content">
            <font-awesome-icon :icon="['fas', 'comment-dots']" class="icon-msg"/>
            <span>
                Club de  Emprendedores <strong>Daniel Hernández</strong>
                Telf. <strong><a href="tel:931934191">931 934191</a></strong>
            </span>
          </div>
        </div>
        <div class="investments">
          <b-row>
            <b-col cols="12" class="col-md-7 left">
              <h3>FORO DE INVERSIONES</h3>
              <p>
                El objetivo del Foro de Inversores es facilitar el encuentro entre
                emprendedores con empresas en fases iniciales de desarrollo, que buscan
                fondos vía participación accionarial, e inversores privados dispuestos
                a financiar proyectos innovadores y rentables compartiendo riesgo
                económico y aportando trabajo y red de contactos. 
                <a href="#">Solicita información.</a>
              </p>
            </b-col>
            <b-col cols="12" class="col-md-5 right">
              <span><font-awesome-icon :icon="['fas', 'file-download']" class="icon-download"/></span>
              <span id="download">
                <strong>DESCARGAR MOLDELO</strong><br>
                DE INSCRIPCIÓN
                <font-awesome-icon :icon="['fas', 'arrow-right']" class="icon-arrow"/>
              </span>
            </b-col>
          </b-row>
        </div>
        <div class="applications">
          <h3>PRESENTACIÓN DE SOLICITUDES</h3>
          <p>
            Los emprendedores interesados en participar en el Foro deberán cumplimentar el
            modelo de solicitud, que podrán descargarse de la web del CLUB de Emprendedores,
            y enviarla a <a href="mailto:estrategia@aicad.es">estrategia@aicad.es</a>
          </p>
          <p>
            Podrán presentarse proyectos de cualquier sector empresarial y con menos de tres
            años de actividad en el mercado.
          </p>
          <p><strong>Los proyectos serán seleccionados según los siguientes criterios:</strong></p>
          <b-row class="rowas">
            <b-col cols="12" class="col-md-6">
              <div>
                <img loading="lazy" alt="icon" src="https://clubejecutivo.s3.us-east-2.amazonaws.com/assets/images/emprendedores/bullet.png" />
                <span>
                  Iniciativa emprendedora innovadora, focalizada, diferencial, oportuna, con
                  cierto componente tecnológico.
                </span>
              </div>
              <div>
                <img loading="lazy" alt="icon" src="https://clubejecutivo.s3.us-east-2.amazonaws.com/assets/images/emprendedores/bullet.png" />
                <span>
                  Equipo humano que sustenta el proyecto.
                </span>
              </div>
              <div>
                <img loading="lazy" alt="icon" src="https://clubejecutivo.s3.us-east-2.amazonaws.com/assets/images/emprendedores/bullet.png" />
                <span>
                  Modelo de negocio.
                </span>
              </div>
            </b-col>
            <b-col cols="12" class="col-md-6">
              <div>
                <img loading="lazy" alt="icon" src="https://clubejecutivo.s3.us-east-2.amazonaws.com/assets/images/emprendedores/bullet.png" />
                <span>
                  Premios o reconocimientos del proyecto.
                </span>
              </div>
              <div>
                <img loading="lazy" alt="icon" src="https://clubejecutivo.s3.us-east-2.amazonaws.com/assets/images/emprendedores/bullet.png" />
                <span>
                  Clientes actuales y escalabilidad.
                </span>
              </div>
              <div>
                <img loading="lazy" alt="icon" src="https://clubejecutivo.s3.us-east-2.amazonaws.com/assets/images/emprendedores/bullet.png" />
                <span>
                  Potencial de revalorización económico-financiera.
                </span>
              </div>
            </b-col>
          </b-row>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  metaInfo() {
    return {
      title: this.$t('emprendedores.financing'),
      meta: [
        // OpenGraph
        { vmid: "og:title", property: "og:title",  content: this.$t('emprendedores.financing') + ' - Club de Ejecutivos' },
        { vmid: "og:type", property: 'og:type', content: 'website' },
        // Google / Schema.org markup:
        { itemprop: 'name', content: this.$t('emprendedores.financing') + ' - Club de Ejecutivos' },
      ]
    }
  }
}
</script>

<style scoped lang="scss">
#img-top {
  width: 100%;
}

.wrapper {
  text-align: left;

  h3:not(#first) {
    margin: 30px 0;
  }

  p {
    margin: 30px 0;
  }
}

.background.first {
    background: #f2f2f2;
    padding: 50px 0;

    #text-financing {
      font-size: 1.5rem;
      font-weight: 300;
      padding: 10px;
      background: white;

      strong {
        font-weight: 600;
      }
    }
}

.second {
    padding: 50px 0;

    a {
        color: #009090;
    }

    .more-info {
        display: flex;
        justify-content: space-around;
        background: #f2f2f2;
        padding: 20px;
        margin-bottom: 30px;

        #text {
            width: 25%;
        }

        #content {
            margin: auto 0;
            .icon-msg {
                color: #009090;
                font-size: 3rem;
                vertical-align: middle;
                margin-right: 10px;
            }
        }
    }

    .investments {

      .right {
        margin: auto 0;
        cursor: pointer;
      }

      .right:hover {
        opacity: 0.9;

        span#download {
          text-decoration: underline;
        }
      }

      .left {
        p {
          width: 85%;
        }
      }

      .icon-download {
        color: #009090;
        font-size: 4rem;
        vertical-align: bottom;
        margin-right: 15px;
      }

      span#download {
        font-size: 2rem;
        line-height: 2rem;
        display: inline-block;
      }
    }

    .applications {
      .rowas {
        margin: 0;

        div {
          padding: 15px 0 0;
          width: 95%;
        }
      }
    }
}

@media screen and (max-width: 767px) {
  .more-info {
    flex-wrap: wrap;

    #text {
      width: unset !important;
    }
  }

  .investments {
    .right {
      text-align: center;
    }
  }
}
</style>